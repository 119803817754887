<template>
  <f7-page class="postview-page">
    <f7-navbar>
      <f7-link @click.stop="$h.goBack()">
        <font-awesome-icon :icon="['far', 'arrow-left']" fixed-width />
      </f7-link>
    </f7-navbar>
    <section class="postview-container">
      <!-- <img style="display: none" :src="$h.getImage(postData.PostImage, 'POST')" /> -->
      <!-- <div class="photo-wrapper" :style="{ 'background-image': $h.getStyleImage(postData.PostImage, 'POST') }"></div> -->

      <f7-swiper class="post-swiper" :pagination="{ type: 'fraction' }" navigation :slides-per-view="1" :space-between="20">
        <template v-if="postData?.PostMediaList?.length > 0">
          <f7-swiper-slide v-for="(media, index) in postData.PostMediaList" :key="'ms_' + index" class="upload-media-container">
            <img v-if="media.Type == 'IMAGE'" :src="$h.getImage(media?.FileName, 'POST', null, false)" :alt="$t.getTranslation('LBL_POST_IMAGE')" loading="lazy" />
            <meta itemprop="thumbnailUrl" :content="$h.getImage(media?.FileName, 'POST', null, false)" />
            <meta itemprop="image" :content="$h.getImage(media?.FileName, 'POST', null, false)" />
            <video v-if="media.Type == 'VIDEO'" controls :data-id="media.PostMediaId">
              <meta itemprop="thumbnailUrl" :content="$h.getImage(postData?.PostVideoPoster, 'POST', null, false)" />
              <meta itemprop="image" :content="$h.getImage(postData?.PostVideoPoster, 'POST', null, false)" />
              <source :src="$h.getVideo(media?.FileName, 'POST')" :type="media?.MimeType || 'video/mp4'" />
              {{ $t.getTranslation("LBL_BROWSER_NO_SUPPORT_VIDEO") }}
            </video>
          </f7-swiper-slide>
        </template>
        <template v-else>
          <f7-swiper-slide class="upload-media-container">
            <img v-if="postData.PostType == 'IMAGE'" :src="$h.getImage(postData?.PostImage, 'POST', null, false)" :alt="$t.getTranslation('LBL_POST_IMAGE')" loading="lazy" />

            <video v-if="postData.PostType == 'VIDEO'" controls>
              <source :src="$h.getVideo(postData?.PostVideo, 'POST')" type="video/mp4" />
              {{ $t.getTranslation("LBL_BROWSER_NO_SUPPORT_VIDEO") }}
            </video>
          </f7-swiper-slide>
        </template>
      </f7-swiper>

      <div class="photo-content">
        <div class="profile">
          <img :src="$h.getImage(postData.UserImage, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" loading="lazy" />
          <div @click="onViewInfluencer(postData?.UserKey)">
            <h3>{{ postData?.UserName || postData?.FirstName || postData?.LastName }}</h3>
            <p>{{ $h.formatDateFromNow(postData.LastCreated) }}</p>
          </div>

          <f7-button fill small :disabled="$h.checkFollowDisabled(postData?.UserKey)" @click="followToggle(postData?.UserKey)">
            {{ postData?.IsFollow === 1 ? $t.getTranslation("LBL_UNFOLLOW") : $t.getTranslation("LBL_FOLLOW") }}
          </f7-button>
        </div>

        <div v-if="postData?.PostText" class="content" v-html="postData.PostText"></div>

        <div v-if="postData?.IsSelfPost == 1" class="views-container">
          <font-awesome-icon :icon="['far', 'eye']" fixed-width />
          <span>{{ $h.formatDisplayNumber(postData?.TotalView) }}</span> {{ $t.getTranslation("LBL_VIEWS") }}
        </div>

        <div class="options">
          <f7-link @click="likeToggle(postData?.PostKey)">
            <font-awesome-icon v-if="postData?.IsLike === 1" :icon="['fas', 'heart']" fixed-width class="fill" />
            <font-awesome-icon v-else :icon="['far', 'heart']" fixed-width />

            <span v-if="postData?.TotalLike > 0">{{ $h.formatDisplayNumber(postData?.TotalLike) }}</span>
            <span v-else>{{ $t.getTranslation("LBL_LIKE") }}</span>
          </f7-link>
          <f7-link @click="showComments">
            <font-awesome-icon :icon="['far', 'comments']" fixed-width />
            <span v-if="postData?.TotalComment > 0">{{ $h.formatDisplayNumber(postData?.TotalComment) }}</span>
            <span v-else>{{ $t.getTranslation("LBL_COMMENT") }}</span>
          </f7-link>
          <f7-link @click="showShare">
            <font-awesome-icon :icon="['far', 'share']" fixed-width />
            {{ $t.getTranslation("LBL_SHARE") }}
          </f7-link>
          <!--  https://cocoho-ph.atlassian.net/browse/PW-193?atlOrigin=eyJpIjoiZGQ5OThkYzA5MzA1NGJiYTk2MjcxMTdiOWUxMDU1YzIiLCJwIjoiaiJ9
            commented by request of cathy -->
          <f7-link v-if="postData?.IsSelfPost == 1" @click="otherActions">
            <font-awesome-icon :icon="['far', 'ellipsis-h']" fixed-width />
          </f7-link>
        </div>

        <template v-if="postData?.ProductList && postData?.ProductList.length > 0">
          <div v-for="(product, ind) in postData?.ProductList" :key="'pr_' + ind">
            <ProductListCardComponent :data="product" type="select" @selected="selectProduct" :isShop="true" />
          </div>
        </template>
        <!-- commented due to task feature/PW-107  -->
        <!-- <NoDataFoundComponent v-else :size="'sm'" :title="$t.getTranslation('LBL_CONNECTED_PRODUCT_EMPTY')" /> -->
      </div>
    </section>

    <f7-actions class="otherActions">
      <f7-actions-group v-if="postData?.IsSelfPost == 1">
        <f7-actions-button @click="editPost">{{ $t.getTranslation("LBL_EDIT") }}</f7-actions-button>
        <f7-actions-button color="red" @click="deletePost">{{ $t.getTranslation("LBL_DELETE") }}</f7-actions-button>
      </f7-actions-group>
      <!-- <f7-actions-group>
        <f7-actions-button color="red" @click="showUserReport">{{ $t.getTranslation("LBL_REPORT") }}</f7-actions-button>
      </f7-actions-group> -->
    </f7-actions>

    <PostCommentComponent v-if="postData && postData.PostKey !== ''" ref="postCommentComponent" :post-data="postData" @updateTotalComment="updateTotalComment" />

    <ShareComponent
      v-if="postData && postData.PostKey !== ''"
      ref="shareComponent"
      :share-data="{
        type: 'POST',
        key: postData.PostKey,
        title: postData.PostTitle || postData.PostText,
        description: postData.PostText,
        qoute: postData.PostText,
        hashtags: postData?.PostTagList,
        video: postData.PostVideo ? $h.getVideo(postData.PostVideo, 'POST') : null,
        media: $h.getImage(postData.PostImage, 'POST'),
      }"
    />

    <UserReportComponent v-if="postData && postData.PostKey !== ''" ref="userReportComponent" :report-key="postData?.PostKey" type="POST" />
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, inject, defineAsyncComponent } from "vue";
import { f7 } from "framework7-vue";
import { Dom7 } from "framework7";
import { get, post } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { useStore } from "@/store";
import _ from "lodash";

// import ProductListCardComponent from "@/components/cards/ProductListCardComponent.vue";
// import PostCommentComponent from "@/components/PostCommentComponent.vue";
// import ShareComponent from "@/components/ShareComponent.vue";
// import UserReportComponent from "@/components/UserReportComponent.vue";

const ProductListCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "product-list-card" */ /* webpackMode: "lazy" */ "@/components/cards/ProductListCardComponent.vue"));
const PostCommentComponent = defineAsyncComponent(() => import(/* webpackChunkName: "post-comment" */ /* webpackMode: "lazy" */ "@/components/PostCommentComponent.vue"));
const ShareComponent = defineAsyncComponent(() => import(/* webpackChunkName: "share" */ /* webpackMode: "lazy" */ "@/components/ShareComponent.vue"));
const UserReportComponent = defineAsyncComponent(() => import(/* webpackChunkName: "user-report" */ /* webpackMode: "lazy" */ "@/components/UserReportComponent.vue"));

export default defineComponent({
  name: "PostViewPage",
  components: {
    ProductListCardComponent,
    PostCommentComponent,
    ShareComponent,
    UserReportComponent,
  },

  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");
    
    const store = useStore();
    
    const postData = ref(false);

    onMounted(async () => {
      if (props?.f7route?.params?.key) await getPostData(props?.f7route?.params?.key);

      Dom7(".photo-content .content a").addClass("external");

      let videoStats = {};

      Dom7("video").forEach((elem) => {
        let id = Dom7(elem).data("id");
        videoStats[id] = { watchTime: 0, skipTime: 0, prevTime: 0, skip: [], PostMediaVideoStatsId: 0 };

        const checkSkipped = (currentTime) => {
          const skipThreshold = 2;
          let periodTime = _.round(currentTime, 2) - _.round(videoStats[id].prevTime, 2);
          periodTime = _.round(periodTime, 2);

          if (periodTime > skipThreshold) {
            let skip = {
              periodTimeSkipped: periodTime,
              startAt: videoStats[id].prevTime,
              endAt: currentTime,
            };

            videoStats[id].prevTime = currentTime;
            videoStats[id].skipTime += periodTime;
            return skip;
          } else {
            videoStats[id].watchTime += periodTime;
          }

          videoStats[id].prevTime = currentTime;
          return false;
        };

        Dom7(elem).on("play", async () => {
          if (videoStats[id].PostMediaVideoStatsId == 0) {
            let returnData = await post("/post/video/stats", { Type: "START", PostMediaId: id, VideoStats: videoStats[id] });
            videoStats[id].PostMediaVideoStatsId = returnData.PostMediaVideoStatsId;
          }
        });

        Dom7(elem).on("pause", async () => {
          await post("/post/video/stats", { Type: "PAUSED", VideoStats: videoStats[id] });
        });

        Dom7(elem).on("timeupdate", async (event) => {
          let skip = checkSkipped(event.target.currentTime);
          if (skip) videoStats[id].skip.push(skip);
        });

        Dom7(elem).on("ended", async () => {
          await post("/post/video/stats", { Type: "ENDED", VideoStats: videoStats[id] });
        });
      });
    });

    const getPostData = async (key) => {
      let ret = await get("/post/view", { PostKey: key, LanguageCode: $t.getLanguage() });
      postData.value = ret;

      increaseView();

      //for share
      let share = await post("/webhook/wechat/share", { url: window.location.href });

      wx.config({
        appId: share.appid, // 必填，公众号的唯一标识
        timestamp: share.timestamp, // 必填，生成签名的时间戳
        nonceStr: share.random, // 必填，生成签名的随机串
        signature: share.signature, // 必
        url: share.url,
        jsApiList: ["updateTimelineShareData"],
      });

      wx.updateTimelineShareData({
        title: postData.value.PostTitle || "PJF Wines - Post Share",
        link: share.url,
        imgUrl: helpers.getImage(postData.value.PostImage, "POST"),
        success: function () {},
        cancel: function () {},
      });
    };

    const otherActions = () => {
      f7.sheet.get(".otherActions").open();
    };

    const onViewInfluencer = (userKey) => {
      props.f7router.navigate({ name: "influencerViewPage", params: { code: userKey } });
    };

    const followToggle = async (UserKey) => {
      if (!helpers.isUserLogin()) {
        props.f7router.navigate({ name: "loginPage" });
        return;
      }

      await post("/follow/toggle", { FollowKey: UserKey, Type: "USER" });
      postData.value.IsFollow = postData.value.IsFollow === 1 ? 0 : 1;
    };

    const likeToggle = async (PostKey) => {
      if (!helpers.isUserLogin()) {
        props.f7router.navigate({ name: "loginPage" });
        return;
      }

      await post("/post/like/toggle", { PostKey: PostKey });

      if (postData.value.IsLike === 1) {
        postData.value.IsLike = 0;
        postData.value.TotalLike--;
      } else {
        postData.value.IsLike = 1;
        postData.value.TotalLike++;
      }
    };

    const increaseView = async () => {
      let postKey = props?.f7route?.params?.key;
      store.dispatch("view/increase", { type: "POST", key: postKey });
    };

    const updateTotalComment = (count) => {
      postData.value.TotalComment = postData.value.TotalComment + count;
    };

    const editPost = () => {
      if (!helpers.isUserLogin()) {
        props.f7router.navigate({ name: "loginPage" });
        return;
      }

      props.f7router.navigate({ name: "postUpdatePage", params: { key: postData?.value?.PostKey } });
    };

    const deletePost = () => {
      if (!helpers.isUserLogin()) {
        props.f7router.navigate({ name: "loginPage" });
        return;
      }

      helpers.createConfirmation({
        message: $t.getTranslation("LBL_CONFIRM_DELETE_POST"),
        confirm: async () => {
          await post("/post/delete", {
            PostKey: postData?.value?.PostKey,
          });

          props.f7router.back({ force: true });
        },
      });
    };

    const selectProduct = (data) => {
      store.dispatch("view/increase", { type: "POSTPRODUCT", key: data.PostProductId });
      let query = {};
      if (postData.value.KolCode) {
        query.ref = postData.value.KolCode;
        //only add post key if from kol
        query.key = postData.value.PostKey;
      }
      props.f7router.navigate({ name: "productViewPage", query: query, params: { code: data.ProductKey } });
    };

    const showShareBtn = ref(true);
    // var ua = navigator.userAgent.toLowerCase()
    // if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    //   showShareBtn.value = false
    // }

    return {
      showShareBtn,
      postData,
      otherActions,
      onViewInfluencer,
      followToggle,
      likeToggle,
      updateTotalComment,
      selectProduct,
      editPost,
      deletePost,
    };
  },
  methods: {
    showComments() {
      this.$refs.postCommentComponent.showCommentSheet(this?.postData?.PostKey);
    },
    showShare() {
      let isWechat = helpers.isInsideWeixin();
      if (isWechat) {
        this.$refs.shareComponent.showShareSheet(null, 1);
        return;
      }
      this.$refs.shareComponent.showShareSheet();
    },
    showUserReport() {
      this.$refs.userReportComponent.showUserReportPopup();
    },
  },
});
</script>

<style scoped>
.content {
  word-break: break-word;
}

.postview-container {
  padding-bottom: 100px;
}
</style>
